<template>
  <div class="maintenance m-2 mt-5 p-3 pb-5">
    <h4 class="text-center">We are undergoing a bit of site maintenance.</h4>
    <h6 class="text-center">Sorry for the inconvenience, but we should be back up quickly. Please check back in 5 minutes.</h6>
    <h6 class="text-center"><b-link @click="healthCheck">Retry</b-link></h6>
  </div>
</template>
<script>
  import ApiProperties from '../../../src/main/resources/application'
  import get from 'just-safe-get'

  export default {
    name: 'maintenance',
    methods: {
      healthCheck() {
        this.$http.get(ApiProperties.api.healthCheck).then(response => {
          if (get(response, "data.status")==="UP") {
            this.$root.navigateToDefaultRoute();
          }
        }).catch(error => {})
      }
    }
  }
</script>
